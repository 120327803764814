import React, {useEffect, useState} from "react";
import {
  Form,
  Divider,
  Table,
  Tag,
  Popover,
  Checkbox,
  Button,
  Modal,
  Spin
} from "antd";
import {BoxInfoCancel, ColCancel, RowCancel} from "./styles"
import { DownloadOutlined } from "@ant-design/icons";
import {
  PageHeader,
  Section,
  Row,
  Col,
  TextField,
  SelectField,
  DateField,
  AreaField
} from "components";

import {
  SupplierStatus,
  supplierStatus,
  SupplierType,
} from "../../../../utils/constants";

import { useParams } from "react-router-dom";

import moment from "moment";

import { useRequest, useProduct, useSuppliers } from "../../../../repositories";

import { useNavigate } from "react-router";

import axios from "services/axios";
import { formatCurrency } from "../../../../utils/helpers";
import { ColFormat, RowFormat } from "./styles";

const options = [
  { label: "Monday", value: "Monday" },
  { label: "Compras", value: "Compras" },
  { label: "Elaw", value: "Elaw" },
  { label: "Cervello", value: "Cervello" },
  { label: "Power BI", value: "Power BI" },
  { label: "TOTVS", value: "TOTVS" },
  { label: "Pacote Office", value: "Pacote Office" },
];
function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
  const onlyNumbers = phoneNumber.replace(/\D/g, "");

  let formattedNumber;
  if (onlyNumbers.length === 11) {
    formattedNumber = onlyNumbers.replace(
        /(\d{2})(\d{5})(\d{4})/,
        "($1) $2-$3"
    );
  } else if (onlyNumbers.length === 10) {
    formattedNumber = onlyNumbers.replace(
        /(\d{2})(\d{4,5})(\d{4})/,
        "($1) $2-$3"
    );
  } else {
    return phoneNumber;
  }

  return formattedNumber;
}

const columnsSuppliers: any = [
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Razão Social",
    dataIndex: "corporateName",
  },
  {
    title: "Nome fantasia ",
    dataIndex: "fantasyName",
  },
  {
    title: "Tipo de Prestador",
    dataIndex: "typeSupplier",
    render: (_: any, record: any) =>
        record.typeSupplier === SupplierType.SUPPLIER
            ? "Fornecedor"
            : "Freelancer",
  },
  {
    title: "Telefone",
    dataIndex: "telephone",
    render: (_: any, record: any) => {
      return applyPhoneMaskWithNinthDigit(record.telephone);
    },
  },
  {
    title: "E-mail",
    dataIndex: "email",
  },
  {
    title: "Status Homologação",
    dataIndex: "status",
    render: (_: any, record: any) => {
      if (record.status === SupplierStatus.APPROVED) {
        return <Tag color="green">Homologado</Tag>;
      }

      if (record.status === SupplierStatus.NOT_APPROVED) {
        return <Tag color="red">Bloqueado</Tag>;
      }

      if (record.status === SupplierStatus.PRE_REGISTRATION) {
        return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
      }

      return <Tag>{supplierStatus[record.status]}</Tag>;
    },
  },
  {
    title: "Preciario",
    dataIndex: "preciary",
    align: "center",
    render: (_: any, record: any) => {
      if (record.preciary) {
        return <Tag>Sim</Tag>;
      } else {
        return <Tag>Não</Tag>;
      }
    },
  },
  {
    title: "Valor negociado",
    dataIndex: "value",
    align: "center",
    render: (_: any, record: any) => (
        <span>{formatCurrency(record.value)}</span>
    ),
  },
  {
    title: "Unidade de medida",
    dataIndex: "unitOfMeasurement",
    align: "center",
  },
];

const requestsColumns: any = [
  {
    title: "Emissão",
    dataIndex: "issueDate",
    render: (_: any, record: any) => {
      return record.issueDate
        ? moment(record.issueDate).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    title: "Vencimento",
    dataIndex: "maturity",
    render: (_: any, record: any) => {
      return record.maturity
        ? moment(record.maturity).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    title: "Produto",
    dataIndex: "productName",
  },
  {
    title: "Prestador",
    dataIndex: "typeProvider",
    render: (_: any, record: any) => {
      return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
    },
  },
  {
    title: "Tipo",
    dataIndex: "typeRequest",
    render: (_: any, record: any) => {
      if (record.typeRequest === "SIMPLE") return "Simples";
      return "Composta";
    },
  },
  {
    title: "Categoria",
    dataIndex: "category",
  },
  {
    title: "Alocação",
    dataIndex: "allocation",
  },
  {
    title: "Local",
    dataIndex: "buName",
  },
  {
    title: "Cliente",
    dataIndex: "client",
  },
  {
    title: "Timing",
    dataIndex: "timing",
    render: (_: any, record: any) => {
      if (record.timing === "PLANEJADA")
        return <Tag color="green">Planejada</Tag>;

      return <Tag color="red">Não planejada</Tag>;
    },
  },
  {
    title: "Fatura",
    dataIndex: "paymentType",
  },
  {
    title: "Valor da requisição",
    dataIndex: "totalCost",
    render: (_: any, record: any) => {
      return formatCurrency(record?.totalCost);
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_: any, record: any) => {
      switch (record.status) {
        case "NEW":
          return <Tag color="cyan">Novo</Tag>;
        case "IN_QUOTATION":
          return <Tag color="yellow">Em cotação</Tag>;
        case "APPROVED":
          return <Tag color="green">Aprovado</Tag>;
        case "REFUSED":
          return <Tag color="orange">Reprovada</Tag>;
        case "CANCELED":
          return <Tag color="red">Cancelada</Tag>;
        case "ON_APPROVAL":
          return <Tag color="yellow">Em aprovação</Tag>;
        case "PAID_OUT":
          return <Tag color="green">Pago</Tag>;
        default:
          return record.status;
      }
    },
  },
];

const RequestDetail = (requestData: any) => {
  const [form] = Form.useForm();
  const [freelancersSuppliersList, setFreelancersSuppliersList] = useState<any>(
    []
  );
  const [productsList, setProductsList] = useState<any>([]);
  const [productsLinesNotEdited, setProductsLinesNotEdited] = useState<any>([]);
  const [filesList, setFilesList] = useState<any>([]);
  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formCanceled, setFormCanceled] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [checkedValues, setCheckedValues] = useState([]);

  const repository = useRequest();
  const repositoryProduct = useProduct();
  const repositorySuplier = useSuppliers();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const { id } = useParams();

  const voltarParaPaginaAnterior = () => {
    navigate(-1);
  };

  const openRequests = (requests: any) => {
    setRequestModal(requests);
    setIsModalOpen(true);
  };

  const columnsLines: any = [
    {
      title: "Id Compras",
      dataIndex: "id",
    },
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Faturamento",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo da linha",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
          <Popover content={record.technicalScopeDescription} overlayStyle={{ maxWidth: "800px" }}>
            {record?.technicalScope}
          </Popover>
        );
      },
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      render: (_: any, record: any) => {
        return record?.amount;
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        return record?.daily;
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.unitCost);
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record?.withoutRequest ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record?.projectRequestItems)}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    {
      title: "Requisições",
      dataIndex: "approvalRequests",
      render: (_: any, record: any) => {
        return formatCurrency(record?.approvalRequests);
      },
    },
    {
      title: "Saldo da linha",
      dataIndex: "lineBalance",
      render: (_: any, record: any) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(record?.lineBalance)}
          </span>
        );
      },
    },
  ];
  const columnsLinesEditable: any = [
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Fat.",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
            <Popover
                content={record.technicalScopeDescription}
                overlayStyle={{ maxWidth: "800px" }}
            >
              {record.technicalScope}
            </Popover>
        );
      },
    },
    {
      title: "Quant",
      dataIndex: "amount",
      render: (_: any, record: any) => {
        return record.amount
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        return record.daily
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.unitCost)
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
    },
  ];

  let initialValues = {
    typeRequest: "",
    category: "Compra & Contratação",
    allocation: "Com projeto",
    requestLocation: "",
    client: "",
    invoiceAgainst: "TV1",
    status: "",
    purchaseType: "Projeto",
    providerType: "",
    senioridade: "",
    timing: 2,
    emission: moment(),
    startDate: moment(),
    endDate: moment(),
    pay: moment(),
    ExpenseOrInvestment: "Despesa",
    productType: "",
    quoteDeadline: "",
    SupplierPricing: "",
    unitMinimunPrice: 0,
    unitMaximunPrice: 0,
    productWithPrice: "",
    cotation: "",
    technicalScope: "",
    objectiveFunction: "",
    justifyIfItIsAnEmergency: "",
    JustifyReasonSingleSupplier: "",
    JustifyUnapprovedSupplier: "",
    JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
    JustifyTheDueDateBeingShorter: "",
    indicationSingleSupplierJustifiable: "",
    needSupportFromTv1corporateTI: "",
    useTv1Email: "",
    equipmentFreelancers: "",
    equipmentTypeFreelancers: "",
    workplace: "",
    accessInternalSystem: "",
    othersAccessInternalSystem: "",
    otherMarketSoftware: "",
    freelanceDirectManager: "",
    contractStartDate: "",
    contractEndDate: "",
  };

  const loadData = async () => {

    setLoading(true)

    const requestResponse = await repository.findRequestById(id, {});

    setFormCanceled(requestResponse.statusRequest === "SUSPEND" || requestResponse.statusRequest === "CANCELED" ? true : false)
    
    const productResponse = await repositoryProduct.findProductById(
      requestResponse?.items[0].productId
    );

    const supplierResponse = await repositorySuplier.findSupplierByFilter({
      id: requestResponse?.suppliers[0].id,
    });

    const productPriceRangeResponse =
      await repositorySuplier.getProductPriceRange(
        requestResponse?.suppliers[0].id
      );

    const paramsProjectItems: any = {
      projectId: requestResponse?.items[0].projectRevision.project.id,
      projectRevisionId: requestResponse?.items[0].projectRevision.id,
      size: 10000
    };

    const responseProjectItems = await repository.findProjectItems(
      paramsProjectItems
    );
    if (!responseProjectItems) return;

    const valuesProducts: any[] = [];

    for (let i = 0; i < requestResponse?.items.length; i++) {
      const item = responseProjectItems?.content[0]?.items
          ?.map((request: any) => ({
            id: String(request.id),
            key: request.id,
            idLinha: request.lineIdErp,
            paymentType: request.billingType === "0" ? "TV1" : "Cliente",
            productId: request.productId,
            productDescription: request.productDescription,
            categoria: request?.categories[0].description,
            technicalScope: request?.description?.slice(0, 10) + " ...",
            technicalScopeDescription: request?.description,
            amount: request.amount,
            daily: request.daily,
            unitCost: request.unitCost,
            totalCost: request.totalCost,
            withoutRequest: request.existsRequisitions,
            packageOrApportionment: request.activePackageOrRating,
            approvalRequests: request.valueApprovedRequisitions,
            lineBalance: request.valueApprovedRequisitions ? request.balanceLine : request.totalCost,
            projectRequestItems: request.projectRequestItems,
          }))
          .filter(
              (item: any) =>
                  item.productId === requestResponse?.items[i]?.productId &&
                  String(item.idLinha) === String(requestResponse?.items[i]?.lineIdErp)
          );

      const valuesProduct = {
        ...item[0],
        categoria: productResponse?.categories[0]?.description,
        totalCost: formatCurrency(requestResponse?.items[i]?.totalCost),
        unitCost: (requestResponse?.items[i]?.totalCost / requestResponse?.items[i]?.daily) / requestResponse?.items[i]?.amount,
        amount: requestResponse?.items[i]?.amount,
        daily: requestResponse?.items[i]?.daily
      };

      valuesProducts.push(valuesProduct)
    }

    const valuesProductsNotEdited: any[] = [];
    for (let i = 0; i < requestResponse?.items.length; i++) {
      const item = responseProjectItems?.content[0]?.items
          ?.map((request: any) => ({
            id: String(request.id),
            key: request.id,
            idLinha: request.lineIdErp,
            paymentType: request.billingType === "0" ? "TV1" : "Cliente",
            productId: request.productId,
            productDescription: request.productDescription,
            categoria: request?.categories[0].description,
            technicalScope: request?.description?.slice(0, 10) + " ...",
            technicalScopeDescription: request?.description,
            amount: request.amount,
            daily: request.daily,
            unitCost: request.unitCost,
            totalCost: request.totalCost,
            withoutRequest: request.existsRequisitions,
            packageOrApportionment: request.activePackageOrRating,
            approvalRequests: request.valueApprovedRequisitions,
            lineBalance: request.valueApprovedRequisitions ? request.balanceLine : request.totalCost,
            projectRequestItems: request.projectRequestItems,
          }))
          .filter(
              (item: any) =>
                  item.productId === requestResponse?.items[i]?.productId &&
                  String(item.idLinha) === String(requestResponse?.items[i]?.lineIdErp)
          );
      valuesProductsNotEdited.push(item[0])
    }
    setProductsLinesNotEdited(valuesProductsNotEdited)

    let unitMinimunPriceClone = "";
    let unitMaximunPriceClone = "";

    switch (supplierResponse?.content[0]?.seniority) {
      case "JUNIOR":
        unitMinimunPriceClone = productResponse?.minimumPriceJunior;
        unitMaximunPriceClone = productResponse?.maximumPriceJunior;
        break;
      case "PLENO":
        unitMinimunPriceClone = productResponse?.minimumPricePleno;
        unitMaximunPriceClone = productResponse?.maximumPricePleno;
        break;
      case "SENIOR":
        unitMinimunPriceClone = productResponse?.minimumPriceSenior;
        unitMaximunPriceClone = productResponse?.maximumPriceSenior;
        break;
    }

    let mappedStatus = "";

    switch (requestResponse?.statusRequest) {
      case "NEW":
        mappedStatus = "Novo";
        break;
      case "IN_QUOTATION":
        mappedStatus = "Em cotação";
        break;
      case "APPROVED":
        mappedStatus = "Aprovado";
        break;
      case "REFUSED":
        mappedStatus = "Reprovada";
        break;
      case "CANCELED":
        mappedStatus = "Cancelada";
        break;
      case "ON_APPROVAL":
        mappedStatus = "Em aprovação";
        break;
      case "PAID_OUT":
        mappedStatus = "Pago";
        break;
      default:
        return requestResponse?.statusRequest;
    }
    const valuesRequest = {
      ...requestResponse,
      needSupportFromTv1corporateTI:
        requestResponse.projectRequestFreelancers
          ?.needSupportFromTv1corporateTI === true
          ? "Sim"
          : "Não",
      useTv1Email:
        requestResponse.projectRequestFreelancers?.useTv1Email === true
          ? "Sim"
          : "Não",
      equipmentFreelancers:
        requestResponse.projectRequestFreelancers?.equipmentFreelancers,
      equipmentTypeFreelancers:
        requestResponse.projectRequestFreelancers?.equipmentTypeFreelancers,
      workplace: requestResponse.projectRequestFreelancers?.workplace,
      accessInternalSystem:
        requestResponse.projectRequestFreelancers?.accessInternalSystem,
      othersAccessInternalSystem:
        requestResponse.projectRequestFreelancers?.othersAccessInternalSystem,
      otherMarketSoftware:
        requestResponse.projectRequestFreelancers?.otherMarketSoftware,
      freelanceDirectManager:
        requestResponse.projectRequestFreelancers?.freelanceDirectManager,
      contractStartDate: moment(
        requestResponse.projectRequestFreelancers?.contractStartDate
      ),
      contractEndDate: moment(
        requestResponse.projectRequestFreelancers?.contractEndDate
      ),
      typeRequest:
        requestResponse?.typeRequest === "SIMPLE" ? "Simples" : "Composta",
      client: requestResponse?.items[0]?.projectRevision?.project?.clientName,
      requestLocation:
        requestResponse?.items[0]?.projectRevision?.project?.buName,
      status: mappedStatus,
      timing: requestResponse?.approvalHierarchyTiming === "PLANEJADA" ? "Planejada" : "Não planejada",
      emission: moment(requestResponse?.issueDate).format("DD/MM/YYYY"),
      startDate: moment(requestResponse?.serviceStartDate).format("DD/MM/YYYY"),
      dateTimeSuspended: moment(requestResponse?.dateTimeSuspended).format("DD/MM/YYYY"),
      endDate: moment(requestResponse?.serviceEndDate).format("DD/MM/YYYY"),
      pay: moment(requestResponse?.maturity).format("DD/MM/YYYY"),
      projectId: requestResponse.items[0].projectRevision.project.idERP,
      projectName: requestResponse.items[0].projectRevision.project.name,
      typeSupplier:
        requestResponse?.typeProvider === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
      technicalScope: requestResponse?.items[0]?.technicalScope,
      seniority: supplierResponse?.content[0]?.seniority,
      unitMinimunPrice: unitMinimunPriceClone,
      unitMaximunPrice: unitMaximunPriceClone,
      ...requestResponse?.projectRequestAdditionalQuestions,
    };

    valuesRequest.productType = productResponse.productType.description;

    const filtered = productPriceRangeResponse.filter(
      (product: any) => product.productId === valuesProducts[0]?.productId
    );
    const valuesSuppliers = {
      ...supplierResponse?.content[0],
      preciary: filtered[0]?.preciary,
      value: filtered[0]?.value ? filtered[0]?.value : "",
      unitOfMeasurement: filtered[0]?.unitOfMeasurement
        ? filtered[0]?.unitOfMeasurement
        : "",
    };

    setFreelancersSuppliersList([valuesSuppliers]);
    setProductsList(valuesProducts);
    setFilesList(requestResponse.files);
    setCheckedValues(
      requestResponse.projectRequestFreelancers?.accessInternalSystem
    );

    form.setFieldsValue(valuesRequest);
    setLoading(false)
  };

  const downloadArchive = async (fileName: any) => {
    try {
      setDownloadLoading(true);
      const response = await axios.get(
        "/v1/manager/project-request/download/" + fileName,
        {
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadLoading(false);
    }
  };

  const onClickGoBack = () => {
    navigate("/");
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <PageHeader
        title="Detalhes da Requisição"
        breadcrumbItems={["Requisições", "Detalhes"]}
      />
      {
        loading ?
        <Section style={{display: 'flex', justifyContent: 'center', padding: '50px 0'}}>
          <Spin />
        </Section>
        :
        <Section>
          <Form form={form} layout="vertical" initialValues={initialValues}>
            <BoxInfoCancel visible={formCanceled}>
              <RowCancel>
                <ColCancel size="half">
                  <TextField
                      name="dateTimeSuspended"
                      label="Data do cancelamento:"
                      disabled
                  />
                </ColCancel>
                <ColCancel size="half">
                  <TextField
                      name="reasonSuspended"
                      label="Motivo do cancelamento"
                      disabled
                  />
                </ColCancel>
              </RowCancel>
              <RowCancel>
                <ColCancel size="full">
                  <AreaField
                      name="descriptionSuspended"
                      label="Justificativa (descreva)"
                      disabled
                  />
                </ColCancel>
              </RowCancel>
            </BoxInfoCancel>
            <Col xl={24}> <b>Categorização, datas e gatilhos</b> </Col>
            <RowFormat>
              <ColFormat>
                <TextField name="client" label="Cliente" disabled />
              </ColFormat>
              <ColFormat>
                <TextField
                    name="projectName"
                    label="Nome do projeto"
                    disabled
                />
              </ColFormat>
              <ColFormat>
                <TextField
                    name="projectId"
                    label="ID do projeto"
                    disabled
                />
              </ColFormat>
              <ColFormat>
                <TextField
                    name="requestLocation"
                    label="Local da requisição"
                    disabled
                />
              </ColFormat>
            </RowFormat>
            <Col xl={24}>
              <b>Preencha as datas do serviço</b>
            </Col>
            <RowFormat>
              <ColFormat>
                <TextField
                    name="startDate"
                    label="Data do início do serviço"
                    disabled
                />
              </ColFormat>
              <ColFormat>
                <TextField
                    name="endDate"
                    label="Data do fim do serviço"
                    disabled
                />
              </ColFormat>
              <ColFormat>
                <TextField
                    name="pay"
                    label="Vencimento [Política]"
                    disabled
                />
              </ColFormat>
              <ColFormat>
                <TextField
                    name="timing"
                    label="Timing da requisição"
                    disabled
                />
              </ColFormat>
            </RowFormat>
            <Col>
              <Divider />
            </Col>
            <Col xl={24}>
              <b>Fornecedores ou freelancers para essa requisição</b>
            </Col>
            <br/>
            <RowFormat>
              <ColFormat>
                <TextField
                    name="typeSupplier"
                    label="Tipo de Prestador"
                    disabled
                />
              </ColFormat>
              <ColFormat>
                <TextField
                    name="seniority"
                    label="Senioridade"
                    disabled
                />
              </ColFormat>
              <ColFormat>
                {
                     form.getFieldValue("typeSupplier") === 'Freelancer' &&
                    <TextField
                        name="unitMaximunPrice"
                        label="Preço Máximo [Freela]"
                        disabled
                    />
                }
              </ColFormat>
            </RowFormat>
            <Col>
              <Table
                  columns={columnsSuppliers}
                  dataSource={freelancersSuppliersList}
                  pagination={false}
              />
            </Col>
            <Col xl={24}>
              <div style={{ marginTop: "20px" }}>
                <b>Linhas e produtos escolhidos para a requisição</b>
              </div>
            </Col>
            <br/>
            <Col xl={24}>
              <Table
                columns={columnsLines}
                dataSource={productsLinesNotEdited}
                pagination={false}
                size='middle'
              />
            </Col>
            <Col xl={24}>
              <div style={{ marginTop: "20px" }}>
                <b>Edite abaixo a quantidade, diarias e o valor dessa requisição</b>
              </div>
            </Col>
            <br/>
            <Col xl={24}>
              <Table
                  columns={columnsLinesEditable}
                  dataSource={productsList}
                  pagination={false}

              />
            </Col>
            <Col>
              <div style={{ marginBottom: "40px" }}></div>
            </Col>
            <Row>
              <Col xl={24}>
                <div style={{ marginTop: "20px" }}>
                  Informações importantes para a alçada de aprovação:
                </div>
              </Col>
              <Col xl={24}>
                <AreaField 
                  name="technicalScope" 
                  label="Escopo técnico detalhado, objetivo da contratação e disponibilidade ou possibilidade de realizar essa função internamente na empresa."
                  disabled 
                  />
              </Col>
            </Row>
            <Row>
              <Col xl={24}><label>Arquivos importantes para a avaliação dos aprovadores</label></Col>
              <Col xl={24}>
                <Table
                  pagination={false}
                  dataSource={filesList}
                  columns={[
                    {
                      title: "Nome do arquivo",
                      dataIndex: "fileName",
                      key: "fileName",
                    },
                    {
                      title: "Formato",
                      dataIndex: "type",
                      key: "type",
                      render(_: any, record: any) {
                        return record?.fileName?.split(".").pop();
                      },
                    },
                    {
                      title: "",
                      dataIndex: "Dawnload",
                      key: "dawnload",
                      render(_: any, record: any) {
                        return (
                          <Button
                            type="link"
                            icon={<DownloadOutlined />}
                            size="small"
                            onClick={() => downloadArchive(record.fileName)}
                            loading={downloadLoading}
                          >
                            Baixar
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col xl={24}>
                <div style={{ marginTop: "20px" }}>
                  <b>Questões complementares para a alçada de aprovação</b>
                </div>
              </Col>
              <Col xl={24}>
                <AreaField
                  name="hiringPurpose"
                  label="Qual o objetivo da contratação? Temos essa função dentro de casa? Avaliamos a disponibilidade?"
                  disabled
                />
              </Col>
            </Row> */}
            {freelancersSuppliersList.length > 0 &&
            freelancersSuppliersList[0].typeSupplier === "FREELANCE" ? (
              <Form.Item className="mt-1">
                <Divider />
                <Row>
                  <Col>
                    <label>
                      Formulário funcional complementar para Freelancers
                    </label>
                    <Divider />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="needSupportFromTv1corporateTI"
                      label="Requer suporte da TI da TV1?"
                      disabled
                      options={[
                        {
                          label: "Sim",
                          value: "sim",
                        },
                        {
                          label: "Não",
                          value: "nao",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="useTv1Email"
                      label="Usará e-mail da TV1?"
                      disabled
                      options={[
                        {
                          label: "Sim",
                          value: "sim",
                        },
                        {
                          label: "Não",
                          value: "nao",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="equipmentFreelancers"
                      label="Equipamento"
                      disabled
                      options={[
                        {
                          label: "Próprio",
                          value: "OWN",
                        },
                        {
                          label: "TV1",
                          value: "TV1",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="equipmentTypeFreelancers"
                      label="Tipo de Equipamento"
                      disabled
                      options={[
                        {
                          label: "Notebook",
                          value: "NOTEBOOK",
                        },
                        {
                          label: "Desktop",
                          value: "DESKTOP",
                        },
                        {
                          label: "IMac",
                          value: "IMAC",
                        },
                        {
                          label: "MacBook",
                          value: "MACBOOK",
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <SelectField
                      name="workplace"
                      label="Local de trabalho"
                      disabled
                      options={[
                        {
                          label: "Escritório TV1",
                          value: "tv1_office",
                        },
                        {
                          label: "Externo",
                          value: "external",
                        },
                        {
                          label: "Home Office",
                          value: "home_office",
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Acesso a sistemas internos da TV1:</label>
                  </Col>
                  <Col>
                    <Checkbox.Group
                      name="accessInternalSystem"
                      options={options}
                      value={checkedValues}
                      disabled
                    />
                  </Col>
                  <br />
                  <br />
                </Row>
                <Row>
                  <Col xl={12} xxl={12}>
                    <AreaField
                      name="othersAccessInternalSystem"
                      label="Outros (descreva)"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} xxl={12}>
                    <TextField
                      name="otherMarketSoftware"
                      label="Outros softwares de mercado:"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} xxl={12}>
                    <TextField
                      name="freelanceDirectManager"
                      label="Quem é o Gestor direto do Freelancer no período em que ele prestar serviço para a TV1?"
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} xxl={4}>
                    <DateField
                      name="contractStartDate"
                      label="*Data do início do contrato."
                      disabled
                    />
                  </Col>
                  <Col xl={6} xxl={4}>
                    <DateField
                      name="contractEndDate"
                      label="*Data do fim do contrato."
                      disabled
                    />
                  </Col>
                </Row>
              </Form.Item>
            ) : (
              ""
            )}
          </Form>
          <Button onClick={voltarParaPaginaAnterior} htmlType="button">
            Voltar
          </Button>
        </Section>
      }
      <Modal
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default RequestDetail;
