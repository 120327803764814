import React, {useEffect, useState} from "react";
import {
  Button,
  Form,
  Table,
  message,
  Upload,
  Divider
} from "antd";
import { Navigate } from "react-router";
import {
  AreaField,
  Section,
  TextField,
  CurrencyField
} from "components";
import { useAuth, usePagination } from "hooks";
import { CloudUploadOutlined } from "@ant-design/icons";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import {UploadWrapper, Container, FormField, Label, Input, Select, GridContainer, GridContainerButton} from "./styles";
import {formatCurrency, formatDateToView} from "utils/helpers";
import moment from "moment";
import NormalizedCurrencyField from "../../../../components/Form/NormalizedCurrencyField";

const FormApportionment = ({
  lines,
  dataForm,
  products,
  selectDataFormApportionment,
  selectTotalValue,
  selectInstallments,
  nextStep,
  prevStep,
  loading,
}: any) => {

  let today: any;

  const { Dragger } = Upload;

  if (dataForm?.timing === 2) {
    today = dataForm?.startDate
  }else {
    today = new Date().toISOString().split('T')[0];
  }

  const columnsApportionment: any = [
    {
      dataIndex: "idErp",
      title: "Id projeto",
    },
    {
      dataIndex : "lineBalance",
      title: "Valor da linha",
      render: (_: any, record: any) => {
        return (
          <span style={{ whiteSpace: "nowrap" }}>
            {formatCurrency(record?.lineBalance)}
          </span>
        );
      },
    },
    {
      dataIndex : "client",
      title: "Cliente",
    },
    {
      dataIndex : "projetoDescription",
      title: "Nome do projeto",
    },
    {
      dataIndex : "idLinha",
      title: "Id da linha TOTVS",
    },
    {
      dataIndex : "paymentType",
      title: "Tipo do Faturamento",
    },
    {
      dataIndex : "productDescription",
      title: "produto",
    },
    {
      dataIndex : "apportionmentPercent",
      align: "center",
      title: "Percentual do rateio (%)",
      render: (_: any, record: any) => {
        // const editable = isEditingApportionmentPercent(record);
        // return editable ? (
        //     <Form form={form}>
        //       <Form.Item name="apportionmentPercent" initialValue={record?.apportionmentPercent}>
        //         <TextField name="apportionmentPercent" label="" textHelp="Percentual do rateio (%)" />
        //       </Form.Item>
        //     </Form>
        // ) : (
        //     record?.apportionmentPercent
        // );
        return record?.apportionmentPercent ? `${record?.apportionmentPercent?.toFixed(2)} %` : '0 %'
      },
    },
    {
      dataIndex : "apportionmentValue",
      title: "Valor",
      render: (_: any, record: any) => {
        const editable = isEditingApportionmentPercent(record);
        return editable ? (
            <Form form={form}>
              <Form.Item name="apportionmentPercent" initialValue={record?.apportionmentValue}>
                <NormalizedCurrencyField name='apportionmentPercent' />
              </Form.Item>
            </Form>
        ) : (
            record?.apportionmentValue ? formatCurrency(record?.apportionmentValue) : formatCurrency(0)
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) =>
          editModeButtonColumnsLine ? (
              <Button
                  style={{backgroundColor: '#ffc168', color: '#000000', borderColor: '#000000'}}
                  onClick={() => {
                    setEditModeButtonColumnsLine(false);
                    handleEdit(record.keyToEdit);
                  }}
              >
                Editar
              </Button>
          ) : (
              <Button
                  style={{backgroundColor: '#e8f7e3'}}
                  onClick={() => {
                    const params = {
                      apportionmentPercent: form.getFieldValue("apportionmentPercent"),
                    };
                    handleSaveProductLine(params);
                    setEditingKeyApportionmentPercent(null);
                    setEditModeButtonColumnsLine(true);
                  }}
              >
                Salvar
              </Button>
          ),
    }
  ];
  const columnsApportionmentWithInstallment: any = [
    {
      title: "Id projeto",
      dataIndex: "projectIdErp",
    },
    {
      title: "Nome do projeto",
      dataIndex: "projectName",
    },
    {
      title: "Percentual do rateio (%)",
      align: "center",
      dataIndex: "apportionmentPercent",
      render: (_: any, record: any) => {
        return `${record?.apportionmentPercent?.toFixed(2)} %`
      },
    },
  ];

  interface Installment {
    value: string;
    dueDate: string;
  }
  interface ProjectInstallment {
    projectIdErp: string;
    projectId: number;
    BU: string;
    apportionmentPercent: number;
    totalValue: number;
    [key: string]: number | string;
  }

  interface Apportion {
    valueApportion: number;
    maturityApportion: string;
  }

  interface ProjectSummary {
    projectId: number;
    projectIdErp: string;
    idLinha: string;
    BU: string;
    apportionmentPercent: number;
    apportions: Apportion[];
  }

  const [form] = Form.useForm();
  const [editModeButtonColumnsLine, setEditModeButtonColumnsLine] = useState(true);
  const [disabledApportionment, setDisabledApportionment] = useState(false);
  const [editingKeyApportionmentPercent, setEditingKeyApportionmentPercent] = useState(null);
  const [btnClick, setBtnClick] = useState("");

  const [totalValue, setTotalValue] = useState('');
  const [numInstallments, setNumInstallments] = useState(1);
  const [installments, setInstallments] = useState<Installment[]>([{ value: '', dueDate: '' }]);
  const [isViewTable, setIsViewTable] = useState(false);
  const [projectInstallments, setProjectInstallments] = useState<any>([]);
  const [columnsInstallment, setColumnsInstallment] = useState<any>([]);
  const [updatedColumns, setUpdatedColumns] = useState<any>([]);

  const [filesItems, setFilesItems] = useState<any>([]);

  const [productsLines, setProductsLines] = useState<any>(
      lines.map((line: any, index: any) => ({keyToEdit: index, ...line}))
  );

  const [recordUpdateLine, setRecordUpdateLine] = useState<any>("");

  const {roles: authRoles, hasRole} = useAuth();

  const pagination = usePagination();

  const isEditingApportionmentPercent = (record: any) => record?.keyToEdit === editingKeyApportionmentPercent;

  const handleEdit = (key: any) => {
    setEditingKeyApportionmentPercent(key);
    const record = productsLines.find(
        (item: any) => key === item.keyToEdit
    );
    setRecordUpdateLine(record);
  };

  const handleSaveProductLine = (params: any) => {

    const id = recordUpdateLine?.keyToEdit;
    let totalValues = 0

    const apportionmentValue = Number(params?.apportionmentPercent.replaceAll('.', '').replace(',', '.'))
    
    let productLine = productsLines?.find(
        (item: any) => item?.keyToEdit === id
    );

    let productWithoutLine = productsLines?.filter(
        (item: any) => item?.keyToEdit !== id
    )

    // productWithoutLine.forEach((line: any) => {

    //   if(line?.apportionmentValue) {
    //     totalValues += line?.apportionmentValue
    //   }
    // });

    productLine.apportionmentValue = Number(apportionmentValue);
    // productLine.apportionmentPercent = Number((apportionmentValue / (totalValues + apportionmentValue)) * 100);

    const productLineEdited = productWithoutLine?.length > 0 ? [productLine].concat(productWithoutLine) : [productLine]

    productLineEdited.forEach((line: any) => {
      if(line?.apportionmentValue) {
        totalValues += line?.apportionmentValue
      }
    });

    const edit = productLineEdited.map((line: any) => ({
      ...line,
      apportionmentPercent: Number(((line?.apportionmentValue / totalValues) * 100))
    }))

    setProductsLines(edit);
    
    const total = totalValues.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    form.setFieldValue("totalValue", total)
    setTotalValue(total)
    selectTotalValue(total)
    setProjectInstallments([])

    // productWithoutLine.forEach((line: any) => {

    //   if(line?.apportionmentPercent) {
    //     sumOfPercentages += line?.apportionmentPercent
    //   }
    // });

    // if(Number(sumOfPercentages) + Number(params?.apportionmentPercent) <= 100) {

    //   productLine.apportionmentPercent = Number(params?.apportionmentPercent);

    //   const productLineEdited = productWithoutLine?.length > 0 ? [productLine].concat(productWithoutLine) : [productLine]
    //   setProductsLines(productLineEdited);
    //   setProjectInstallments([])

    // }else {
    //   message.warning('O somatório das porcentagens não pode ultrapassar 100%')
    // }
    form.setFieldValue("apportionmentPercent","")
  };

  const apportionmentForm = async () => {

  };

  let initialValues = {
    objectiveFunction: "",
    technicalScope: "",
  };

  useEffect(() => {
    if (isViewTable){
      const updatedColumns = [
        ...columnsApportionmentWithInstallment,
        ...columnsInstallment,
        {
          title: "total",
          dataIndex: "totalValue",
          render: (_: any, record: any) => {
            return <span>{formatCurrency(record?.totalValue)}</span>
          }
        },
      ]
      setUpdatedColumns(updatedColumns)
    }
  }, [isViewTable])

  useEffect(() => {
    if (pagination.data.current) apportionmentForm();
  }, [
    pagination.data.current,
    pagination.data.pageSize,
    columnsApportionmentWithInstallment
  ]);
  useEffect(() => {
    if (dataForm.providerType === 2 && dataForm.timing === 1) {
      handleInstallmentChange(0, 'dueDate', moment(dataForm.endDate).add(70, "days").format('YYYY-MM-DD'))
    }

    if (dataForm.providerType === 1 && dataForm.timing === 1) {
      handleInstallmentChange(0, 'dueDate', moment(dataForm.startDate).add(30, "days").format('YYYY-MM-DD'))
    }
  }, [])

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado"/>;
  }

  const summarizeInstallmentsWithDates = (
      projectInstallments: ProjectInstallment[],
      installments: Installment[]
  ): ProjectSummary[] => {
    const result: ProjectSummary[] = [];

    projectInstallments.forEach(project => {
      const projectSummary: ProjectSummary = {
        projectId: project.projectId,
        idLinha: String(project.idLinha),
        projectIdErp: project.projectIdErp,
        BU: project.BU,
        apportionmentPercent: project.apportionmentPercent,
        apportions: []
      };

      for (let i = 0; i < installments.length; i++) {
        const installmentKey = `${i + 1}installment`;
        if (project[installmentKey] !== undefined) {
          const valueApportion = project[installmentKey] as number;
          projectSummary.apportions.push({
            valueApportion: valueApportion,
            maturityApportion: installments[i].dueDate
          });
        }
      }
      result.push(projectSummary);
    });

    return result;
  }

  const onFinish = async (values: any, btn: any = 2) => {

    if(projectInstallments?.length <= 0){
      message.error("Salve os dados de parcelamento")
      return
    }
    const apportions = summarizeInstallmentsWithDates(projectInstallments, installments);
    const installmentsItems = lines.map((line:  any) => {

      let projectInstallmentsCurrent
      let apportionsCurrent

      if ( apportions.every((item: any) => item?.projectId === apportions[0]?.projectId)){
         projectInstallmentsCurrent = projectInstallments.find((obj: any) => parseInt(obj.idLinha.replace(/\./g, ''), 10) === parseInt(line.idLinha.replace(/\./g, ''), 10));
         apportionsCurrent = apportions.find((obj: any) => parseInt(obj.idLinha.replace(/\./g, ''), 10) === parseInt(line.idLinha.replace(/\./g, ''), 10));
      } else {
         projectInstallmentsCurrent = projectInstallments.find((obj: any) => obj.projectId === line.projectId && obj.idLinha === line.idLinha)
         apportionsCurrent = apportions.find((obj: any) => obj.projectId === line.projectId && obj.idLinha === line.idLinha)
      }
      return {
        productId: line.productId,
        costCenterId: line.costCenterId,
        buId: line.BUId,
        projectRevisionItemId: line.projectRevisionItemId,
        amount: line.amount,
        daily: line.daily,
        unitCost: Number(projectInstallmentsCurrent.totalValue?.toFixed(2)),
        unitSale: line.unitSale,
        totalSalesWithoutTax: Number(projectInstallmentsCurrent.totalValue?.toFixed(2)),
        technicalScope:  form.getFieldValue('technicalScope'),
        totalValue: Number(projectInstallmentsCurrent.totalValue?.toFixed(2)),
        valueApportion: Number(projectInstallmentsCurrent?.totalValue?.toFixed(2)),
        percentage: projectInstallmentsCurrent.apportionmentPercent,
        projectId: line.projectId,
        apportions: apportionsCurrent?.apportions.map(apportion => ({...apportion, valueApportion: Number(apportion?.valueApportion?.toFixed(2))})),
      };
    });


    const data = {
      // totalValue: Number(values.totalValue.replace(/\./g, '').replace(/,/g, '').slice(0, -2)),
      totalValue: Number(values?.totalValue?.replaceAll('.', '')?.replace(',', '.')),
      objectiveFunction: values.objectiveFunction,
      technicalScope: values.technicalScope,
      installments: installmentsItems,
      btnStatus: btn,
      files: filesItems,
    };

    switch (btnClick) {
      case "back":
        selectDataFormApportionment();
        prevStep();
        break;
      case "next":
        selectDataFormApportionment(data);
        nextStep();
        break;
      case "save":
        selectDataFormApportionment(data);
        break;
      default:
        selectDataFormApportionment();
        prevStep();
        break;
    }
  };

  const prev = (e: any) => {
    prevStep();
  };

  const handleNumInstallmentsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const count = parseInt(e.target.value, 10);
    setNumInstallments(count);
    const newInstallments = Array.from({ length: count }, (_, i) => (
        installments[i] || { value: '', dueDate: '' }
    ));
    setInstallments(newInstallments);
  };

  const handleInstallmentChange = (index: number, field: keyof Installment, value: string) => {
    const newInstallments = [...installments];
    newInstallments[index][field] = value;
    setInstallments(newInstallments);
    selectInstallments(newInstallments);
  };

  const handleSubmit = () => {

    let totalParcelas = 0
    let FieldsValidation = false

    const serviceStart = dataForm.startDate;
    const serviceEnd = dataForm.endDate;

    if (dataForm.providerType === 2 && dataForm.timing === 1 && installments.length > 0) {
      const firstDueDate = installments[0].dueDate;
      const minimumDueDate = moment(serviceEnd).add(70, "days")

      if (moment(firstDueDate).isBefore(minimumDueDate)) {
        message.error(`Para fornecedores com requisição planejada, a primeira parcela deve ter a data de vencimento com, pelo menos,
         70 dias após o término do serviço preenchido no primeiro step ${moment(serviceEnd).format("DD/MM/YYYY")}`);
        return
      }
    }

    if (dataForm.providerType === 1 && dataForm.timing === 1 && installments.length > 0) {
      const firstDueDate = moment(installments[0].dueDate);
      const minimumDueDate = moment(serviceStart).add(30, "days")
      if (firstDueDate.isBefore(minimumDueDate)) {
        message.error(`De acordo com a data de início do serviço preenchida no primeiro step ${moment(serviceStart).format("DD/MM/YYYY")},
         o vencimento da primeira parcela precisa ser a partir de ${minimumDueDate.format("DD/MM/YYYY")} [30 dias após o início do serviço]  }).`);
        return
      }
    }

    for (let i = 0; i < installments.length; i++) {
      const dueDate = installments[i].dueDate;
      const installmentValue = installments[i].value;

      if (parseInt(installmentValue) <= 0){
        message.error(`O valor da ${i+1}° parcela não pode ter valor igual 0`);
        return
      }

      if (moment(dueDate).isBefore(serviceStart)) {
        message.error(`O vencimento dessa parcela ${moment(dueDate).format("DD/MM/YYYY")}, não pode ser anterior à data de início do serviço preenchida no primeiro step ${moment(serviceStart).format("DD/MM/YYYY")}`);
        return
      }

      if (i > 0) {
        const previousDueDate = installments[i - 1].dueDate;
        if (moment(dueDate).isSameOrBefore(previousDueDate)) {
          message.error(`Você não pode escolher uma data de vencimento de parcela que seja menor que o vencimento da parcela anterior. A parcela com vencimento em ${moment(dueDate).format("DD/MM/YYYY")} precisa ter, pelo menos, 1 dia a mais que a parcela anterior ${moment(previousDueDate).format("DD/MM/YYYY")}`);
          return
        }
      }
    }

    installments.some((installment) => {
      if(installment.value && installment.dueDate){
        totalParcelas = Number((totalParcelas + Number(installment.value?.replaceAll('.', '')?.replace(',', '.'))).toFixed(2))
        return false;
      }else {
        FieldsValidation = true
        return true;
      }
    });
    if(FieldsValidation || totalValue.length <= 0) {
      message.warning('Antes de salvar o parcelamento preencha todas as informações das parcelas');
      return
    }

    if(totalParcelas > Number(totalValue?.replaceAll('.', '')?.replace(',', '.'))) {
      message.warning('O somatório das parcelas não pode ultrapassar o valor total do rateio');
      return
    }


    if(totalParcelas < Number(totalValue?.replaceAll('.', '')?.replace(',', '.'))) {
      message.warning('O somatório das parcelas não pode ser menor que o valor total do rateio');
      return
    }

    const projectMap = new Map();

    productsLines.forEach((line: { idErp: string; BU: string; apportionmentPercent: number; idLinha: string;}) => {
      const { idErp, BU, apportionmentPercent, idLinha } = line || {};

      if (!projectMap.has(idErp)) {
        projectMap.set(idErp, {
          projectIdErp: idErp,
          idLinha: idLinha,
          BU: BU,
          apportionmentPercent: apportionmentPercent,
          lines: []
        });
      }
      projectMap.get(idErp).lines.push(line);
    });

    const projectObjects: any[] = [];

    productsLines.forEach((project: any) => {

      const { idErp, apportionmentPercent, projectId, projetoDescription, idLinha } = project;

      const totalInstallmentsValue = (Number(totalValue?.replaceAll('.', '')?.replace(',', '.')) * apportionmentPercent) / 100;

      const installmentValue = totalInstallmentsValue / installments.length;

      const installmentObject: any = {};

      installments.forEach((installment, index) => {
        installmentObject[`${index + 1}installment`] = (Number(installment.value?.replaceAll('.', '')?.replace(',', '.')) * apportionmentPercent) / 100;
      });


      const calculationTotal = installmentValue * installments.length;

      

      const projectObject = {
        projectIdErp: idErp,
        projectId: projectId,
        idLinha: idLinha,
        projectName: projetoDescription,
        apportionmentPercent: apportionmentPercent,
        ...installmentObject,
        totalValue: calculationTotal
      };

      projectObjects.push(projectObject);
    });

    const columnsInstallment: any = []
    installments.forEach((_, index) => {
      columnsInstallment.push({
        title: `${index + 1}° Parcela \n ${formatDateToView(_.dueDate)}`,
        dataIndex: `${index + 1}installment`,
        render: (_: any, record: any) => {
          return <span>{formatCurrency(record[`${index + 1}installment`])}</span>
        }
      });
    });

    setColumnsInstallment(columnsInstallment)
    setProjectInstallments(projectObjects)
    setIsViewTable(true);
    setDisabledApportionment(true);
  };

  const props = {
    name: "file",
    multiple: true,
    action: "",
    customRequest: (file: any) => {
      setFilesItems([...filesItems, file.file]);
    },
    onDrop(e: any) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const removeFile = (file: any) => {
    const index = filesItems.findIndex((obj: any) => obj.uid === file.uid);
    const newArray = [...filesItems];
    if (index !== -1) {
      newArray.splice(index, 1);
      setFilesItems(newArray);
    }
  };

  return (
      <>
        <Section>
          <div style={{marginTop: 24}}>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={initialValues}
            >
              <>
                <Row>
                  <Col>
                    <Table
                        columns={columnsApportionment}
                        dataSource={productsLines}
                        pagination={false}
                    />
                  </Col>
                </Row>
                <br/>
                <Col xl={24}>
                  <b>Como será a programação do rateio ?</b>
                </Col>
                <br/>
                <Container>
                  <div>
                    <FormField>
                      <Label htmlFor="totalValue">Valor Total</Label>
                      <CurrencyField
                          name='totalValue'
                          onChange={(e) => {
                            selectTotalValue(e.target.value)
                            setTotalValue(e.target.value)
                          }}
                          disabled
                      />
                    </FormField>
                    <FormField>
                      <Label htmlFor="numInstallments">Número de Parcelas</Label>
                      <Select
                          id="numInstallments"
                          value={numInstallments}
                          onChange={handleNumInstallmentsChange}
                          disabled={disabledApportionment}
                      >
                        {[...Array(dataForm?.providerType === 1 ? 3 : 12 )].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div
                      style={{
                        border: '1px solid #ccc',
                        padding: '16px',
                        backgroundColor: '#fff2f0',
                        borderRadius: '8px',
                        width: '80%',
                        margin: '20px auto',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        alignItems: 'end'
                      }}
                  >
                    {dataForm.providerType === 1 ?

                        (<>
                          <b>Atenção às politicas internas</b>

                          <p style={{ margin: 0, fontSize: '16px', color: '#333' }}>
                            ▸O rateio para freelancers pode ter até 3 parcelas
                          </p>
                          <br/>
                          <p style={{ margin: 0, fontSize: '16px', color: '#333' }}>
                            ▸Para freelancers com requisição planejada, a primeira parcela deve ter a data de vencimento com, pelo menos, 30 dias após o início do serviço.
                          </p> </>)
                        :
                        (<>
                          <b>Atenção às politicas internas</b>
                          <p style={{ margin: 0, fontSize: '16px', color: '#333' }}>
                            ▸O rateio para fornecedores pode ter até 12 parcelas
                          </p>
                          <br/>
                          <p style={{ margin: 0, fontSize: '16px', color: '#333' }}>
                            ▸Para fornecedores com requisição planejada, a primeira parcela deve ter a data de vencimento com, pelo menos, 70 dias após o término do serviço.
                          </p> </>)}
                  </div>
                  <GridContainer>
                    {installments.map((installment, index) => (
                        <React.Fragment key={index}>
                          <FormField>
                            <Label htmlFor={`installmentValue-${index}`}><span style={{color: '#ff4d4f'}}>*</span> Valor da Parcela {index + 1}</Label>
                            <NormalizedCurrencyField
                              name={`installmentValue-${index}`}
                              onChange={(e) => {
                                handleInstallmentChange(index, 'value', e.target.value)
                                }
                              }
                              disabled={disabledApportionment || (index <= 0 ? false : !installments[index - 1]?.dueDate)}
                            />
                          </FormField>
                          <FormField>
                            <Label htmlFor={`dueDate-${index}`}><span style={{color: '#ff4d4f'}}>*</span> Vencimento Parcela {index + 1}</Label>
                            <Input
                                id={`dueDate-${index}`}
                                type="date"
                                value={installment.dueDate}
                                onChange={(e) => {

                                  handleInstallmentChange(index, 'dueDate', e.target.value)
                                }}
                                min={index <= 0 ? installment.dueDate : installments[index - 1]?.dueDate}
                                disabled={disabledApportionment || (index <= 0 ? false : !installments[index - 1]?.dueDate)}
                                style={{marginBottom: '10px'}}
                            />
                          </FormField>
                        </React.Fragment>
                    ))}
                  </GridContainer>
                  <GridContainerButton>
                    <Button
                        style={{ backgroundColor: "#e8f7e3" }}
                        onClick={
                          disabledApportionment ?
                              () => {
                                setDisabledApportionment(false)
                                setIsViewTable(false)
                                setProjectInstallments([])
                              } :
                              handleSubmit
                        }
                    >{disabledApportionment ? "Editar" : "Salvar"}</Button>
                  </GridContainerButton>
                </Container>
                <Row>
                  {isViewTable ? (
                    <Col>
                      <Table
                          columns={updatedColumns}
                          dataSource={projectInstallments}
                          pagination={false}
                      />
                    </Col>
                  ) : ""}
                  <Col>
                    <div style={{marginBottom: "40px"}}></div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={24}>
                    <div style={{ marginTop: "20px" }}>
                      <b>Informações importantes para a alçada de aprovação:</b>
                    </div>
                  </Col>
                  {/* <Col xl={24}>
                    <AreaField
                        required
                        name="objectiveFunction"
                        maxLength={-1}
                        label="Qual o objetivo da contratação? Temos essa função dentro de casa? Avaliamos a disponibilidade?"
                        rules={[
                          {
                            required: true,
                            message: "Objetivo obrigatório",
                          },
                        ]}
                    />
                  </Col>
                  <Col xl={24}>
                    <div style={{ marginTop: "20px" }}>
                      <b>Escopo técnico detalhado e anexos</b>
                    </div>
                  </Col> */}
                  <Col xl={24}>
                    <AreaField
                        required
                        name="technicalScope"
                        maxLength={-1}
                        label="Por favor, descreva o escopo técnico detalhado, o objetivo da contratação e se há disponibilidade ou possibilidade de realizar essa função internamente na empresa."
                        rules={[
                          {
                            required: true,
                            message: "Campo obrigatório",
                          },
                        ]}
                    />
                  </Col>
                  <Col xl={24}><label>Por favor, anexar arquivos que sejam importantes para a avaliação dos aprovadores</label></Col>
                  <Col xl={24}>
                    <UploadWrapper>
                      <Dragger {...props} showUploadList={false}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CloudUploadOutlined
                            style={{ fontSize: "24px", marginTop: "-5px" }}
                          />
                          <p className="ant-upload-text">
                            Clique ou arraste para realizar o upload
                          </p>
                        </div>
                      </Dragger>
                    </UploadWrapper>
                  </Col>
                </Row>
                <Row>
                <Col>
                  <div></div>
                </Col>
                <Col xl={24}>
                  <Table
                    pagination={false}
                    dataSource={filesItems}
                    columns={[
                      {
                        title: "Nome do arquivo",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Formato",
                        dataIndex: "type",
                        key: "type",
                      },
                      {
                        title: "",
                        dataIndex: "delete",
                        key: "delete",
                        render(_: any, record: any) {
                          return (
                            <span
                              onClick={() => removeFile(record)}
                              style={{ cursor: "pointer" }}
                            >
                              excluir
                            </span>
                          );
                        },
                      },
                    ]}
                  />
                </Col>
                
              </Row>
              <Row>
                  <Col>
                    <Divider />
                  </Col>
                </Row>
                <Button style={{margin: "0 4px"}} onClick={prev} >
                  Voltar
                </Button>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.providerType !== currentValues.providerType
                    }
                >
                  {
                    dataForm.providerType === 1 ?
                      <Button
                        style={{margin: "0 4px"}}
                        type="primary"
                        htmlType="submit"
                        onClick={() => setBtnClick("next")}
                      >
                        Próximo
                      </Button>
                    :
                    <Button
                      style={{margin: "0 4px"}}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      onClick={() => setBtnClick("save")}
                    >
                      Salvar
                    </Button>
                  }
                  
                </Form.Item>
              </>
            </Form>
          </div>
        </Section>
      </>
  );
};

export default FormApportionment;
